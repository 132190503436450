import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Header from "../components/header"
import Button from "../components/button"

import { css } from "@emotion/react"
import { FaInstagram } from "react-icons/fa"
import { MdToday, MdUpdate } from "react-icons/md"

const BlogPage = (props) => {

    const articles = props.data.articles.edges.map( article => article.node )
    // console.log("PROPPPPS", props)

    // console.log("ARTICLES", articles)

    return (
      <Layout>
          <SeO 
            title="Blog du Digital en Suisse romande"
            desc="Conseils et bonnes pratiques web, updates sur le digital en Suisse romande, par l'agence Lyketil.com [Digital Lab] à Lausanne." 
            lang="fr"
            slug={props.uri}
          />
    
          <Header 
              title="Blog Digital en Suisse romande"
              subtitle="Idées et bonnes pratiques web"
              intro="Bienvenue sur notre blog digital, où nous vous partageons quelques-unes de nos idées, conseils, ou nouveautés !"
              lang={"fr-fr"}
          />
    
          
          <div className="container">
          <Button link={"https://www.instagram.com/lyketil/"} text={"Suivez-nous sur Instagram"} icon={<FaInstagram />} />

          <section css={blogSection}>
            {articles.map(article => {

                return (
                    <article key={article.uid}>
                        <div className="blog-card__image">
                            <Img fluid={article.data.image.fluid} />
                        </div>
                        <div className="blog-card__main">
                            <h3 className="section-subtitle">{article.tags.join(" - ")}</h3>
                            <Link to={`/blog/${article.uid}`}><h2>{article.data.title.text}</h2></Link>
                            <div className="blog-card__meta"><MdToday /> Publié le {article.data.publication_date}. <MdUpdate /> Updaté le {article.last_publication_date}</div>
                            <p>{article.data.excerpt}</p>
                        </div>
                    </article>
                )
            })}
          </section>
        </div>
      </Layout>
    )
}


export default BlogPage

const blogSection = css`
    padding: 100px 0;

    article {
        border-radius: 25px;
        background-color: #f0f0f0;
        margin-bottom: 60px;
        
        @media screen and (min-width: 750px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .blog-card__image {
            width: 100%;
            border-radius: 25px 25px 0 0;
            overflow: hidden;
            
            @media screen and (min-width: 750px) {
                width: 25%;
                position: relative;
                left: -20px;
                border-radius: 10px;
            }
        }

        .blog-card__main {
            padding: 30px;

            @media screen and (min-width: 750px) {
                width: 75%;
            }
        }

        .blog-card__meta {
            font-size: .8rem;
            font-family: 'Courier Prime', Courier, monospace;
            margin-bottom: 10px;
            opacity: .5;

            svg {
                margin-bottom: -3px;
            }
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        h2 {
            font-size: 1.2rem;
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 5px;
        }

        p{
            margin-bottom: 0;
        }
    }
`

export const indexQuery = graphql`
query BlogQuery {
    articles: allPrismicArticles(sort: {order: DESC, fields: data___publication_date}) {
      edges {
        node {
          uid
          last_publication_date(
            formatString: "DD MMMM YYYY"
            locale: "fr"
          )
          tags
          data {
            title {
              text
            }
            excerpt
            image {
                fluid(imgixParams: {q: 100}) {
                    ...GatsbyPrismicImageFluid
                }
            }
            publication_date(
                formatString: "DD MMMM YYYY"
                locale: "fr"
            )
          }
        }
      }
    }
  }
`